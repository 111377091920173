import React from 'react';

/**
 * Input Component
 *
 * @param {string} props.label - The label text for the input
 * @param {string} [props.labelHint] - Optional text to display next to the label
 * @param {string} [props.leftAddon] - Text to display as a left addon
 * @param {React.ReactElement} [props.leftIcon] - Icon to display on the left side of the input
 * @param {string} [props.inlineLeftAddon] - Text to display as an inline left addon
 * @param {string} [props.className] - Additional CSS classes for the input
 * @param {string} [props.inlineRightAddon] - Text to display as an inline right addon
 * @param {React.ReactElement} [props.rightIcon] - Icon to display on the right side of the input
 * @param {string} [props.rightAddon] - Text to display as a right addon
 * @param {string} [props.hint] - Hint text to display below the input
 * @param {string} [props.error] - Error message to display below the input
 * @param {string} [props.size='md'] - Size of the input ('xs', 'sm', 'md', 'lg', 'xl')
 * @param {Object} props - The properties passed to the component
 * @returns {React.ReactElement} An input element with label and optional icons or addons
 */
const Input = ({
  label,
  labelHint,
  leftAddon,
  leftIcon: LeftIcon,
  inlineLeftAddon,
  className,
  rightIcon: RightIcon,
  rightAddon,
  inlineRightAddon,
  hint,
  error,
  size = 'md',
  disabled,
  required,
  value = '',
  ...props
}) => {
  const hasLeftAddon = leftAddon || inlineLeftAddon;
  const hasRightAddon = rightAddon || inlineRightAddon;
  const hasInlineAddon = inlineLeftAddon || inlineRightAddon;

  const sizeClasses = {
    xs: 'px-2 py-1 text-xs',
    sm: 'px-2 py-1 text-sm',
    md: 'px-2.5 py-1.5 text-sm',
    lg: 'px-3 py-2 text-sm',
    xl: 'px-3.5 py-2.5 text-sm'
  };

  return (
    <div className='w-full'>
      <div className='flex justify-between'>
        <label className='block text-sm font-medium leading-6 text-base-900'>
          {label}
          {required && <span className='text-danger-700 ml-1'>*</span>}
        </label>
        {labelHint && (
          <span className='text-sm leading-6 text-base-500'>{labelHint}</span>
        )}
      </div>
      <div
        className={`flex ${
          hasInlineAddon
            ? ` ring-1 ring-inset ${disabled && 'bg-base-100 text-base-500'} ${
                error
                  ? 'ring-danger-300 focus-within:ring-danger-600'
                  : required && !value
                  ? 'ring-danger-300 focus-within:ring-danger-600'
                  : 'ring-base-300 focus-within:ring-primary-600'
              } focus-within:ring-2 focus-within:ring-inset rounded-md `
            : ''
        }`}
      >
        {leftAddon && (
          <span
            className={`inline-flex items-center rounded-l-md border border-r-0 border-base-300 px-0.5 text-base-500 sm:text-sm`}
          >
            {leftAddon}
          </span>
        )}
        <div className='relative flex-grow flex items-center'>
          {inlineLeftAddon && (
            <span className='flex items-center ml-3.5 text-base-500 sm:text-sm'>
              {inlineLeftAddon}
            </span>
          )}
          <div className='relative flex-grow'>
            {LeftIcon && (
              <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
                <LeftIcon className='h-5 w-5 text-base-400' />
              </div>
            )}
            <input
              disabled={disabled}
              className={`block w-full min-w-0 ${
                hasInlineAddon
                  ? 'border-0 bg-transparent text-base-900 disabled:text-base-500 placeholder:text-base-400 focus:ring-0'
                  : `border-0 text-base-900 disabled:text-base-500 ring-1 ring-inset ${
                      error
                        ? 'ring-danger-300 focus:ring-danger-600'
                        : 'ring-base-300 focus:ring-primary-600'
                    } placeholder:text-base-400 focus:ring-2 focus:ring-inset`
              } ${sizeClasses[size]} sm:leading-6${LeftIcon ? ' pl-10' : ''} ${
                RightIcon ? ' pr-10' : ''
              }${!hasLeftAddon && !hasInlineAddon ? ' rounded-l-md' : ''}${
                !hasRightAddon && !hasInlineAddon ? ' rounded-r-md' : ''
              }${disabled ? ' cursor-not-allowed text-base-500' : ''}${
                className ? ` ${className}` : ''
              }`}
              value={value}
              {...props}
            />
            {RightIcon && (
              <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3'>
                <RightIcon className='h-5 w-5 text-base-400' />
              </div>
            )}
          </div>
          {inlineRightAddon && (
            <span className='flex items-center mr-3.5 text-base-500 sm:text-sm'>
              {inlineRightAddon}
            </span>
          )}
        </div>
        {rightAddon && (
          <span
            className={`inline-flex items-center rounded-r-md border border-l-0 border-base-300 px-3 text-base-500 sm:text-sm`}
          >
            {rightAddon}
          </span>
        )}
      </div>
      {error ? (
        <p className='mt-1 text-sm text-danger-600'>{error}</p>
      ) : (
        hint && <p className='mt-1 text-sm text-base-500'>{hint}</p>
      )}
    </div>
  );
};

export { Input };
