import BaseModel from './BaseModel';

/**
 * Represents a User Profile in the system.
 * @extends BaseModel
 */
class UserProfile extends BaseModel {
  /** @type {string} The name of the database table for User Profiles */
  static table = 'user_profiles';

  /** @type {string} The SQL query to select User Profile data */
  static selectQuery =
    '*, role:permission_groups!inner(*), companies!user_profiles_company_id_fkey(id,name)';

  /**
   * Creates a UserProfile instance from database format data
   * @param {Object} data - The database format user profile data
   * @returns {UserProfile} A new UserProfile instance
   */
  static fromDatabase(data = {}) {
    const userProfile = new UserProfile();
    userProfile.id = data.id;
    userProfile.createdDate = data.created_date;
    userProfile.updatedDate = data.updated_date;
    userProfile.auth = data.auth;
    userProfile.firstName = data.first_name;
    userProfile.lastName = data.last_name;
    userProfile.fullName = data.full_name;
    userProfile.email = data.email;
    userProfile.phones = data.phones || [];
    userProfile.profilePhoto = data.profile_photo;
    userProfile.companyId = data.company_id;
    userProfile.company = data.companies
      ? { id: data.companies.id, name: data.companies.name }
      : null;
    userProfile.levy = data.levy;
    userProfile.metrics = data.metrics || {};
    userProfile.active = data.active;
    userProfile.displayId = data.display_id;
    userProfile.bohsaAdmin = data.bohsa_admin;
    userProfile.companyAdmin = data.company_admin;
    userProfile.role = data.role;
    userProfile.crmPreferences = data.crm_preferences;
    userProfile.dateOfBirth = data.date_of_birth;
    userProfile.nationalInsurance = data.national_insurance;
    userProfile.homeAddress = data.home_address;
    userProfile.metadata = data.metadata;
    return userProfile;
  }

  /**
   * Creates an instance of UserProfile.
   * @param {Object} data - The user profile data.
   */
  constructor(data = {}) {
    super(data);
    this.auth = data.auth ?? this.auth ?? undefined;
    this.firstName = data.firstName ?? this.firstName ?? undefined;
    this.lastName = data.lastName ?? this.lastName ?? undefined;
    this.fullName = data.fullName ?? this.fullName ?? undefined;
    this.email = data.email ?? this.email ?? undefined;
    this.phones = data.phones ?? this.phones ?? [];
    this.profilePhoto = data.profilePhoto ?? this.profilePhoto ?? undefined;
    this.companyId = data.companyId ?? this.companyId ?? undefined;
    this.company = data.company ?? this.company ?? undefined;
    this.levy = data.levy ?? this.levy ?? undefined;
    this.metrics = data.metrics ?? this.metrics ?? {};
    this.active = data.active ?? this.active ?? false;
    this.displayId = data.displayId ?? this.displayId ?? undefined;
    this.bohsaAdmin = data.bohsaAdmin ?? this.bohsaAdmin ?? false;
    this.companyAdmin = data.companyAdmin ?? this.companyAdmin ?? false;
    this.role = data.role ?? this.role ?? undefined;
    this.crmPreferences = data.crmPreferences ?? this.crmPreferences ?? {};
    this.dateOfBirth = data.dateOfBirth ?? this.dateOfBirth ?? undefined;
    this.nationalInsurance =
      data.nationalInsurance ?? this.nationalInsurance ?? undefined;
    this.homeAddress = data.homeAddress ?? this.homeAddress ?? {};
    this.metadata = data.metadata ?? this.metadata ?? {};
  }

  /**
   * Converts the UserProfile instance to a database-friendly format.
   * @returns {Object} The user profile data ready for database operations.
   */
  toDatabase() {
    return {
      ...super.toDatabase(),
      auth: this.auth,
      first_name: this.firstName,
      last_name: this.lastName,
      email: this.email,
      phones: this.phones,
      profile_photo: this.profilePhoto,
      active: this.active,
      metrics: this.metrics,
      //display_id: this.displayId, // Include display_id in the database format
      bohsa_admin: this.bohsaAdmin,
      company_admin: this.companyAdmin,
      company_id: this.companyId,
      role: this.role?.id,
      crm_preferences: this.crmPreferences,
      date_of_birth: this.dateOfBirth,
      national_insurance: this.nationalInsurance,
      home_address: this.homeAddress,
      metadata: this.metadata
    };
  }

  /**
   * Get a record by its display ID.
   * @param {string|number} id - The ID of the record to fetch.
   * @returns {Promise<BaseModel>} A promise that resolves to a new instance of the model.
   */
  static async getById(id) {
    return this.fetchOne('display_id', id, this.selectQuery);
  }
  /**
   * Get a record by its unique id
   * @param {string|number} id - The ID of the record to fetch.
   * @returns {Promise<BaseModel>} A promise that resolves to a new instance of the model.
   */
  static async getByUniqueId(id) {
    return this.fetchOne('id', id, this.selectQuery);
  }

  /**
   * Fetch a user profile by auth ID.
   * @param {string} id - The auth ID to fetch.
   * @returns {Promise<UserProfile>} A promise that resolves to a new instance of UserProfile.
   */
  static async getByAuthId(id, activeOnly = false) {
    const filters = { auth: { value: id } };
    if (activeOnly) {
      filters.active = { value: true };
    }
    return this.getAll(filters);
  }

  /**
   * Check if the user has a specific permission for a given entity.
   * @param {number} entityId - The ID of the entity to check permissions for.
   * @param {number} permissionFlag - The permission flag to check (e.g., 1 for read, 2 for write, 4 for update, 8 for delete).
   * @returns {boolean} True if the user has the specified permission, false otherwise.
   */
  hasPermission(entityId, permissionFlag) {
    // Check if the user has permissions for the given entity
    if (this.role.permissions && this.role.permissions[entityId]) {
      // Perform bitwise AND operation to check if the permission flag is set
      return (
        (this.role.permissions[entityId] & permissionFlag) === permissionFlag
      );
    }
    return false; // No permissions found for the entity
  }
}

export default UserProfile;
