import React, { createContext, useContext, useMemo } from 'react';

const AiContext = createContext();

export const AiProvider = ({ children, handleAiRequest }) => {
  // Memoize the context value to prevent unnecessary re-renders
  const value = useMemo(() => ({ handleAiRequest }), [handleAiRequest]);

  return <AiContext.Provider value={value}>{children}</AiContext.Provider>;
};

export const useAi = () => {
  const context = useContext(AiContext);
  if (!context) {
    throw new Error('useAi must be used within an AiProvider');
  }
  return context;
};
