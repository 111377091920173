import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle
} from 'react';
import {
  useEditor,
  EditorContent,
  ReactNodeViewRenderer,
  BubbleMenu,
  FloatingMenu,
  NodeViewContent,
  NodeViewWrapper,
  ReactRenderer
} from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';
import Typography from '@tiptap/extension-typography';
import Link from '@tiptap/extension-link';
import Image from '@tiptap/extension-image';
import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight';
import { DragHandle } from '@tiptap-pro/extension-drag-handle-react';
import TextAlign from '@tiptap/extension-text-align';
import CharacterCount from '@tiptap/extension-character-count';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import Mention from '@tiptap/extension-mention';
import tippy from 'tippy.js';

import { common, createLowlight } from 'lowlight';
import {
  Bold,
  Italic,
  Strikethrough,
  Code,
  Eraser,
  Trash2,
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
  Heading6,
  List,
  ListOrdered,
  FileCode,
  Quote,
  Minus,
  Undo,
  Redo,
  Type,
  X,
  Video as VideoIcon,
  Image as ImageIcon,
  GripVertical,
  AlignLeft,
  AlignRight,
  AlignCenter,
  AlignJustify,
  Sparkles,
  TextSearch,
  IterationCw,
  Smile,
  Briefcase,
  FoldVertical,
  Pen,
  TableIcon,
  ArrowLeft,
  ArrowRight
} from 'lucide-react';

// custom Extensions
import { SmilieReplacer } from '../../../utilities/tiptap/SmilieReplacer';
import { Video } from '../../../utilities/tiptap/video';
import AiContentNode from '../../../utilities/tiptap/AiContentNode';
import { Modal } from '../../feedback/alerts/Modal';
import { Input } from '../forms/Input';

import Toolbar from '../../../utilities/tiptap/Toolbar';

const CodeBlockComponent = ({ node, updateAttributes, extension }) => {
  const defaultLanguage = node.attrs.language;

  return (
    <NodeViewWrapper className='relative'>
      <select
        contentEditable={false}
        defaultValue={defaultLanguage}
        onChange={event => updateAttributes({ language: event.target.value })}
        className='absolute right-2 top-2 rounded-md px-1.5 py-1'
      >
        <option value='null'>auto</option>
        <option disabled>—</option>
        {extension.options.lowlight.listLanguages().map((lang, index) => (
          <option key={index} value={lang}>
            {lang}
          </option>
        ))}
      </select>
      <pre>
        <NodeViewContent as='code' />
      </pre>
    </NodeViewWrapper>
  );
};

// create a lowlight instance
const lowlight = createLowlight(common);

const TextEditor = ({
  label,
  labelHint,
  placeholder,
  characterLimit,
  value,
  onChange,
  className,
  hint,
  type = 'standard',
  onEditorReady,
  onMentionSearch,
  onMentionSelect,
  ...props
}) => {
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);

  const [videoUrl, setVideoUrl] = useState('');
  const [imageUrl, setImageUrl] = useState('');

  const extensions = [
    StarterKit.configure({
      codeBlock: false
    }),
    Placeholder.configure({
      placeholder: placeholder || 'Write something...'
    }),
    SmilieReplacer,
    Typography,
    Video,
    AiContentNode.configure({
      content: '',
      status: 'idle'
    }),
    Image.configure({
      HTMLAttributes: {
        class: 'w-full h-full rounded-lg'
      }
    }),
    CodeBlockLowlight.extend({
      addNodeView() {
        return ReactNodeViewRenderer(CodeBlockComponent);
      }
    }).configure({
      lowlight
    }),
    Link.configure({
      openOnClick: false,
      autolink: true,
      defaultProtocol: 'https'
    }),
    TextAlign.configure({
      types: ['heading', 'paragraph']
    }),
    Table.configure({
      resizable: true
    }),
    TableRow,
    TableHeader,
    TableCell,
    Mention.configure({
      HTMLAttributes: {
        class: 'mention bg-info-100 text-info-800 px-1 py-0.5 rounded-md'
      },
      suggestion: {
        items: ({ query }) => {
          if (onMentionSearch) {
            return onMentionSearch(query);
          }
          return [];
        },
        render: () => {
          let reactRenderer;
          let popup;
          return {
            onStart: props => {
              reactRenderer = new ReactRenderer(MentionList, {
                props: {
                  ...props,
                  onMentionSelect
                },
                editor: props.editor
              });

              popup = tippy('body', {
                getReferenceClientRect: props.clientRect,
                appendTo: () => document.body,
                content: reactRenderer.element,
                showOnCreate: true,
                interactive: true,
                trigger: 'manual',
                placement: 'bottom-start'
              })[0];
            },
            onUpdate(props) {
              reactRenderer.updateProps({
                ...props,
                onMentionSelect
              });

              popup.setProps({
                getReferenceClientRect: props.clientRect
              });
            },
            onKeyDown(props) {
              if (props.event.key === 'Escape') {
                popup.hide();
                return true;
              }

              return reactRenderer.ref?.onKeyDown(props);
            },
            onExit() {
              popup.destroy();
              reactRenderer.destroy();
            }
          };
        }
      }
    }),
    ...(characterLimit !== undefined
      ? [
          CharacterCount.configure({
            limit: characterLimit
          })
        ]
      : [])
  ];

  const editor = useEditor({
    editorProps: {
      attributes: {
        class: 'prose prose-neutral max-w-none focus:outline-none'
      },
      parseOptions: {
        preserveWhitespace: 'full'
      }
    },
    immediatelyRender: true,
    shouldRerenderOnTransaction: true,
    extensions,
    content: value,
    onUpdate: ({ editor }) => {
      const htmlContent = editor.getHTML();
      onChange(htmlContent);
    }
  });

  // Add this useEffect to notify parent when editor is ready
  useEffect(() => {
    if (editor && onEditorReady) {
      onEditorReady(editor);
    }
  }, [editor, onEditorReady]);

  useEffect(() => {
    if (editor && value !== editor.getHTML()) {
      editor.commands.setContent(value);
    }
  }, [value, editor]);

  if (!editor) {
    return null;
  }

  const addVideo = () => {
    if (videoUrl) {
      editor.commands.setVideo(videoUrl);
      setVideoUrl('');
      setShowVideoModal(false);
    }
  };

  const addImage = () => {
    if (imageUrl) {
      editor.chain().focus().setImage({ src: imageUrl }).run();
      setImageUrl('');
      setShowImageModal(false);
    }
  };

  const MentionList = forwardRef((props, ref) => {
    const [selectedIndex, setSelectedIndex] = useState(0);

    const selectItem = index => {
      const item = props.items[index];
      if (item) {
        props.command({ id: item.id, label: item.name });
        if (props.onMentionSelect) {
          props.onMentionSelect(item);
        }
      }
    };

    const upHandler = () => {
      setSelectedIndex(
        (selectedIndex + props.items.length - 1) % props.items.length
      );
    };

    const downHandler = () => {
      setSelectedIndex((selectedIndex + 1) % props.items.length);
    };

    const enterHandler = () => {
      selectItem(selectedIndex);
    };

    useEffect(() => setSelectedIndex(0), [props.items]);

    useImperativeHandle(ref, () => ({
      onKeyDown: ({ event }) => {
        if (event.key === 'ArrowUp') {
          upHandler();
          return true;
        }

        if (event.key === 'ArrowDown') {
          downHandler();
          return true;
        }

        if (event.key === 'Enter') {
          enterHandler();
          return true;
        }

        return false;
      }
    }));

    if (props.items.length === 0) {
      return null;
    }

    return (
      <div className='bg-white rounded-md shadow-lg border border-base-200 overflow-hidden py-1 max-h-60 overflow-y-auto'>
        {props.items.map((item, index) => (
          <button
            key={index}
            className={`block w-full text-left px-3 py-2 ${
              index === selectedIndex ? 'bg-primary-50' : ''
            }`}
            onClick={() => selectItem(index)}
          >
            <div className='flex items-center'>
              {item.avatar && (
                <img
                  src={item.avatar}
                  alt={item.name}
                  className='w-6 h-6 rounded-full mr-2'
                />
              )}
              <span className='font-medium'>{item.name}</span>
              {item.description && (
                <span className='text-sm text-gray-500 ml-2'>
                  {item.description}
                </span>
              )}
            </div>
          </button>
        ))}
      </div>
    );
  });

  const percentage =
    characterLimit !== undefined && editor
      ? Math.round(
          (100 / characterLimit) * editor.storage.characterCount.characters()
        )
      : 0;

  return (
    <div
      className={`flex flex-col border border-base-300 rounded-md overflow-hidden ${className}`}
    >
      <DragHandle editor={editor}>
        <GripVertical
          size={16}
          className='items-center cursor-grab flex -mr-1 my-1 text-center justify-center text-base-500'
        />
      </DragHandle>
      <Toolbar editor={editor} />
      <EditorContent editor={editor} className='overflow-y-auto flex-1 p-6' />
      {characterLimit && (
        <div
          className={`p-6 mt-4 character-count text-xs items-center gap-2 flex ${
            editor.storage.characterCount.characters() === characterLimit
              ? 'character-count--warning'
              : ''
          }`}
        >
          <svg
            height='20'
            width='20'
            viewBox='0 0 20 20'
            className='text-primary-700'
          >
            <circle r='10' cx='10' cy='10' fill='#e9ecef' />
            <circle
              r='5'
              cx='10'
              cy='10'
              fill='transparent'
              stroke='currentColor'
              strokeWidth='10'
              strokeDasharray={`calc(${percentage} * 31.4 / 100) 31.4`}
              transform='rotate(-90) translate(-20)'
            />
            <circle r='6' cx='10' cy='10' fill='white' />
          </svg>
          {editor.storage.characterCount.characters()} / {characterLimit}{' '}
          characters
          <br />
          {editor.storage.characterCount.words()} words
        </div>
      )}

      {showVideoModal && (
        <Modal
          isOpen={showVideoModal}
          onClose={() => setShowVideoModal(false)}
          title='Add Video URL'
          style='primary'
          actions={[
            {
              label: 'Insert Video',
              onClick: addVideo,
              style: 'primary'
            },
            {
              label: 'Cancel',
              onClick: () => setShowVideoModal(false),
              style: 'base'
            }
          ]}
        >
          <Input
            value={videoUrl}
            onChange={e => setVideoUrl(e.target.value)}
            placeholder='Enter video URL'
            autoFocus
          />
        </Modal>
      )}

      {showImageModal && (
        <Modal
          isOpen={showImageModal}
          onClose={() => setShowImageModal(false)}
          title='Add Image URL'
          style='primary'
          actions={[
            {
              label: 'Insert Image',
              onClick: addImage,
              style: 'primary'
            },
            {
              label: 'Cancel',
              onClick: () => setShowImageModal(false),
              style: 'base'
            }
          ]}
        >
          <Input
            value={imageUrl}
            onChange={e => setImageUrl(e.target.value)}
            placeholder='Enter image URL'
            autoFocus
          />
        </Modal>
      )}
    </div>
  );
};

export { TextEditor };
