import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle
} from '@headlessui/react';
import { Check, AlertTriangle, XCircle } from 'lucide-react';
import { FilledButton, SoftButton } from '@core/components';

const Modal = ({
  isOpen,
  onClose,
  title,
  children,
  actions,
  style = 'primary'
}) => {
  const getIcon = () => {
    switch (style) {
      case 'success':
        return (
          <Check className='h-6 w-6 text-success-600' aria-hidden='true' />
        );
      case 'warning':
        return (
          <AlertTriangle
            className='h-6 w-6 text-warning-600'
            aria-hidden='true'
          />
        );
      case 'danger':
        return (
          <XCircle className='h-6 w-6 text-danger-600' aria-hidden='true' />
        );
      default:
        return null;
    }
  };

  const getIconBackground = () => {
    switch (style) {
      case 'success':
        return 'bg-success-100';
      case 'warning':
        return 'bg-warning-100';
      case 'danger':
        return 'bg-danger-100';
      default:
        return '';
    }
  };

  const icon = getIcon();

  return (
    <Dialog open={isOpen} onClose={onClose} className='relative z-50'>
      <DialogBackdrop
        transition
        className='fixed inset-0 bg-base-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-100 data-[enter]:ease-out data-[leave]:ease-in'
      />

      <div className='fixed inset-0 z-10 w-screen overflow-y-auto'>
        <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
          <DialogPanel
            transition
            className='relative transform overflow-visible rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95'
          >
            <div>
              {icon && (
                <div
                  className={`mx-auto flex h-12 w-12 items-center justify-center rounded-full ${getIconBackground()}`}
                >
                  {icon}
                </div>
              )}
              <div className='mt-3 text-center sm:mt-5'>
                <DialogTitle
                  as='h3'
                  className='text-base font-semibold leading-6 text-primary-900'
                >
                  {title}
                </DialogTitle>
                <div className='mt-2'>{children}</div>
              </div>
            </div>
            {actions && (
              <div className='mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3'>
                {actions.map((action, index) => (
                  <FilledButton
                    key={index}
                    onClick={action.onClick}
                    disabled={action.disabled}
                    colour={action.style || style}
                    className={`w-full justify-center ${
                      index === 0 ? 'sm:col-start-2' : 'sm:col-start-1 sm:mt-0'
                    }`}
                  >
                    {action.label}
                  </FilledButton>
                ))}
              </div>
            )}
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};

export { Modal };
