import React, { createContext, useContext, useState, useCallback } from 'react';
import { NotificationItem } from '@core/components';

const NotificationContext = createContext();

export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    console.warn('useNotification must be used within a NotificationProvider');
    return {
      addNotification: () => {
        console.warn('NotificationProvider not found');
        return Date.now();
      },
      removeNotification: () => {
        console.warn('NotificationProvider not found');
      }
    };
  }
  return context;
};

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);

  const addNotification = useCallback(notification => {
    const id = notification.id || Date.now();

    setNotifications(prevNotifications => {
      // Check if notification with this ID already exists
      const existingIndex = prevNotifications.findIndex(n => n.id === id);

      if (existingIndex >= 0) {
        // Update existing notification
        const updatedNotifications = [...prevNotifications];
        updatedNotifications[existingIndex] = {
          ...prevNotifications[existingIndex],
          ...notification,
          id
        };
        return updatedNotifications;
      } else {
        // Add new notification
        return [...prevNotifications, { ...notification, id }];
      }
    });

    return id;
  }, []);

  const removeNotification = useCallback(id => {
    setNotifications(prevNotifications =>
      prevNotifications.filter(notification => notification.id !== id)
    );
  }, []);

  return (
    <NotificationContext.Provider
      value={{ addNotification, removeNotification }}
    >
      {children}
      <div
        aria-live='assertive'
        className='pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-[999]'
      >
        <div className='flex w-full flex-col items-center space-y-4 sm:items-end'>
          {notifications.map((notification, index) => (
            <NotificationItem
              key={notification.id}
              type={notification.type || 'success'}
              duration={notification.duration || 4000}
              onClose={() => removeNotification(notification.id)}
              title={notification.title}
              description={notification.description}
            />
          ))}
        </div>
      </div>
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
