import React, { useState } from 'react';
import {
  Bold,
  Italic,
  Strikethrough,
  Eraser,
  Trash2,
  Type,
  AlignLeft,
  AlignCenter,
  AlignRight,
  AlignJustify,
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
  Heading6,
  List,
  ListOrdered,
  FileCode,
  Quote,
  Minus,
  VideoIcon,
  ImageIcon,
  TableIcon,
  Undo,
  Redo
} from 'lucide-react';
import AiMenu from './AiMenu';

const Toolbar = ({ editor, setShowVideoModal, setShowImageModal }) => {
  const [showTableMenu, setShowTableMenu] = useState(false);

  if (!editor) {
    return null;
  }

  return (
    <div className='flex flex-col'>
      <div className='flex flex-wrap gap-1 p-1 bg-base-100 border-b border-base-300'>
        {/* Bold text formatting */}
        <button
          onMouseDown={e => {
            e.preventDefault();
            editor.chain().focus().toggleBold().run();
          }}
          className={`p-2 rounded hover:bg-base-200 ${
            editor.isActive('bold') ? 'bg-base-200' : ''
          }`}
          title='Bold'
        >
          <Bold size={18} />
        </button>
        {/* Italic text formatting */}
        <button
          onMouseDown={e => {
            e.preventDefault();
            editor.chain().focus().toggleItalic().run();
          }}
          className={`p-2 rounded hover:bg-base-200 ${
            editor.isActive('italic') ? 'bg-base-200' : ''
          }`}
          title='Italic'
        >
          <Italic size={18} />
        </button>
        {/* Strikethrough text formatting */}
        <button
          onMouseDown={e => {
            e.preventDefault();
            editor.chain().focus().toggleStrike().run();
          }}
          className={`p-2 rounded hover:bg-base-200 ${
            editor.isActive('strike') ? 'bg-base-200' : ''
          }`}
          title='Strikethrough'
        >
          <Strikethrough size={18} />
        </button>
        {/* Clear all text formatting */}
        <button
          onMouseDown={e => {
            e.preventDefault();
            editor.chain().focus().unsetAllMarks().run();
          }}
          className='p-2 rounded hover:bg-base-200'
          title='Clear Formatting'
        >
          <Eraser size={18} />
        </button>
        {/* Clear all block formatting */}
        <button
          onMouseDown={e => {
            e.preventDefault();
            editor.chain().focus().clearNodes().run();
          }}
          className='p-2 rounded hover:bg-base-200'
          title='Clear Nodes'
        >
          <Trash2 size={18} />
        </button>
        {/* Convert to paragraph */}
        <button
          onMouseDown={e => {
            e.preventDefault();
            editor.chain().focus().setParagraph().run();
          }}
          className={`p-2 rounded hover:bg-base-200 ${
            editor.isActive('paragraph') ? 'bg-base-200' : ''
          }`}
          title='Paragraph'
        >
          <Type size={18} />
        </button>
        {/* Align Left */}
        <button
          onMouseDown={e => {
            e.preventDefault();
            editor.chain().focus().setTextAlign('left').run();
          }}
          className={`p-2 rounded hover:bg-base-200 ${
            editor.isActive({ textAlign: 'left' }) ? 'bg-base-200' : ''
          }`}
          title='Align Left'
        >
          <AlignLeft size={18} />
        </button>
        {/* Align Center */}
        <button
          onMouseDown={e => {
            e.preventDefault();
            editor.chain().focus().setTextAlign('center').run();
          }}
          className={`p-2 rounded hover:bg-base-200 ${
            editor.isActive({ textAlign: 'center' }) ? 'bg-base-200' : ''
          }`}
          title='Align Center'
        >
          <AlignCenter size={18} />
        </button>
        {/* Align Right */}
        <button
          onMouseDown={e => {
            e.preventDefault();
            editor.chain().focus().setTextAlign('right').run();
          }}
          className={`p-2 rounded hover:bg-base-200 ${
            editor.isActive({ textAlign: 'right' }) ? 'bg-base-200' : ''
          }`}
          title='Align Right'
        >
          <AlignRight size={18} />
        </button>
        {/* Justify */}
        <button
          onMouseDown={e => {
            e.preventDefault();
            editor.chain().focus().setTextAlign('justify').run();
          }}
          className={`p-2 rounded hover:bg-base-200 ${
            editor.isActive({ textAlign: 'justify' }) ? 'bg-base-200' : ''
          }`}
          title='Justify'
        >
          <AlignJustify size={18} />
        </button>
        {/* Heading level 1 */}
        <button
          onMouseDown={e => {
            e.preventDefault();
            editor.chain().focus().toggleHeading({ level: 1 }).run();
          }}
          className={`p-2 rounded hover:bg-base-200 ${
            editor.isActive('heading', { level: 1 }) ? 'bg-base-200' : ''
          }`}
          title='Heading 1'
        >
          <Heading1 size={18} />
        </button>
        {/* Heading level 2 */}
        <button
          onMouseDown={e => {
            e.preventDefault();
            editor.chain().focus().toggleHeading({ level: 2 }).run();
          }}
          className={`p-2 rounded hover:bg-base-200 ${
            editor.isActive('heading', { level: 2 }) ? 'bg-base-200' : ''
          }`}
          title='Heading 2'
        >
          <Heading2 size={18} />
        </button>
        {/* Heading level 3 */}
        <button
          onMouseDown={e => {
            e.preventDefault();
            editor.chain().focus().toggleHeading({ level: 3 }).run();
          }}
          className={`p-2 rounded hover:bg-base-200 ${
            editor.isActive('heading', { level: 3 }) ? 'bg-base-200' : ''
          }`}
          title='Heading 3'
        >
          <Heading3 size={18} />
        </button>
        {/* Heading level 4 */}
        <button
          onMouseDown={e => {
            e.preventDefault();
            editor.chain().focus().toggleHeading({ level: 4 }).run();
          }}
          className={`p-2 rounded hover:bg-base-200 ${
            editor.isActive('heading', { level: 4 }) ? 'bg-base-200' : ''
          }`}
          title='Heading 4'
        >
          <Heading4 size={18} />
        </button>
        {/* Heading level 5 */}
        <button
          onMouseDown={e => {
            e.preventDefault();
            editor.chain().focus().toggleHeading({ level: 5 }).run();
          }}
          className={`p-2 rounded hover:bg-base-200 ${
            editor.isActive('heading', { level: 5 }) ? 'bg-base-200' : ''
          }`}
          title='Heading 5'
        >
          <Heading5 size={18} />
        </button>
        {/* Heading level 6 */}
        <button
          onMouseDown={e => {
            e.preventDefault();
            editor.chain().focus().toggleHeading({ level: 6 }).run();
          }}
          className={`p-2 rounded hover:bg-base-200 ${
            editor.isActive('heading', { level: 6 }) ? 'bg-base-200' : ''
          }`}
          title='Heading 6'
        >
          <Heading6 size={18} />
        </button>
        {/* Unordered (bullet) list */}
        <button
          onMouseDown={e => {
            e.preventDefault();
            editor.chain().focus().toggleBulletList().run();
          }}
          className={`p-2 rounded hover:bg-base-200 ${
            editor.isActive('bulletList') ? 'bg-base-200' : ''
          }`}
          title='Bullet List'
        >
          <List size={18} />
        </button>
        {/* Ordered (numbered) list */}
        <button
          onMouseDown={e => {
            e.preventDefault();
            editor.chain().focus().toggleOrderedList().run();
          }}
          className={`p-2 rounded hover:bg-base-200 ${
            editor.isActive('orderedList') ? 'bg-base-200' : ''
          }`}
          title='Numbered List'
        >
          <ListOrdered size={18} />
        </button>
        {/* Code block with syntax highlighting */}
        <button
          onMouseDown={e => {
            e.preventDefault();
            editor.chain().focus().toggleCodeBlock().run();
          }}
          className={`p-2 rounded hover:bg-base-200 ${
            editor.isActive('codeBlock') ? 'bg-base-200' : ''
          }`}
          title='Code Block'
        >
          <FileCode size={18} />
        </button>
        {/* Block quote */}
        <button
          onMouseDown={e => {
            e.preventDefault();
            editor.chain().focus().toggleBlockquote().run();
          }}
          className={`p-2 rounded hover:bg-base-200 ${
            editor.isActive('blockquote') ? 'bg-base-200' : ''
          }`}
          title='Block Quote'
        >
          <Quote size={18} />
        </button>
        {/* Horizontal rule (divider) */}
        <button
          onMouseDown={e => {
            e.preventDefault();
            editor.chain().focus().setHorizontalRule().run();
          }}
          className='p-2 rounded hover:bg-base-200'
          title='Horizontal Rule'
        >
          <Minus size={18} />
        </button>
        {/* Add Video */}
        <button
          onMouseDown={e => {
            e.preventDefault();
            setShowVideoModal(true);
          }}
          className='p-2 rounded hover:bg-base-200'
          title='Add Video'
        >
          <VideoIcon size={18} />
        </button>
        {/* Add Image */}
        <button
          onMouseDown={e => {
            e.preventDefault();
            setShowImageModal(true);
          }}
          className='p-2 rounded hover:bg-base-200'
          title='Add Image'
        >
          <ImageIcon size={18} />
        </button>
        {/* Table Controls */}
        <div className='relative'>
          <button
            onMouseDown={e => {
              e.preventDefault();
              setShowTableMenu(prev => !prev);
            }}
            className='p-2 rounded hover:bg-base-200'
            title='Table Controls'
          >
            <TableIcon size={18} />
          </button>

          {showTableMenu && (
            <div className='absolute left-0 mt-1 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50'>
              <div className='py-1' role='menu'>
                <button
                  className='w-full text-left px-4 py-2 text-sm hover:bg-base-100'
                  onMouseDown={e => {
                    e.preventDefault();
                    editor
                      .chain()
                      .focus()
                      .insertTable({ rows: 3, cols: 3, withHeaderRow: true })
                      .run();
                    setShowTableMenu(false);
                  }}
                >
                  Insert Table (3x3)
                </button>
                <button
                  className='w-full text-left px-4 py-2 text-sm hover:bg-base-100'
                  onMouseDown={e => {
                    e.preventDefault();
                    editor.chain().focus().deleteTable().run();
                    setShowTableMenu(false);
                  }}
                >
                  Delete Table
                </button>
                <button
                  className='w-full text-left px-4 py-2 text-sm hover:bg-base-100'
                  onMouseDown={e => {
                    e.preventDefault();
                    editor.chain().focus().addColumnBefore().run();
                    setShowTableMenu(false);
                  }}
                >
                  Add Column Before
                </button>
                <button
                  className='w-full text-left px-4 py-2 text-sm hover:bg-base-100'
                  onMouseDown={e => {
                    e.preventDefault();
                    editor.chain().focus().addColumnAfter().run();
                    setShowTableMenu(false);
                  }}
                >
                  Add Column After
                </button>
                <button
                  className='w-full text-left px-4 py-2 text-sm hover:bg-base-100'
                  onMouseDown={e => {
                    e.preventDefault();
                    editor.chain().focus().addRowBefore().run();
                    setShowTableMenu(false);
                  }}
                >
                  Add Row Before
                </button>
                <button
                  className='w-full text-left px-4 py-2 text-sm hover:bg-base-100'
                  onMouseDown={e => {
                    e.preventDefault();
                    editor.chain().focus().addRowAfter().run();
                    setShowTableMenu(false);
                  }}
                >
                  Add Row After
                </button>
              </div>
            </div>
          )}
        </div>
        {/* Undo last change */}
        <button
          onMouseDown={e => {
            e.preventDefault();
            editor.chain().focus().undo().run();
          }}
          className='p-2 rounded hover:bg-base-200'
          title='Undo'
        >
          <Undo size={18} />
        </button>
        {/* Redo last undone change */}
        <button
          onMouseDown={e => {
            e.preventDefault();
            editor.chain().focus().redo().run();
          }}
          className='p-2 rounded hover:bg-base-200'
          title='Redo'
        >
          <Redo size={18} />
        </button>

        {/* AI Menu */}
        <AiMenu editor={editor} />
      </div>
    </div>
  );
};

export default Toolbar;
