import React, { useState, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import {
  X,
  ArrowLeft,
  Sparkles,
  TextSearch,
  Smile,
  Briefcase,
  FoldVertical,
  Type,
  List
} from 'lucide-react';
import {
  FilledButton,
  FlatButton,
  SoftButton
} from '../../components/inputs/buttons/Button';
import { useAi } from '../../components/providers/AiProvider';

const AiMenu = ({ editor }) => {
  const [showAiMenu, setShowAiMenu] = useState(false);
  const [showDescribeInput, setShowDescribeInput] = useState(false);
  const [describeText, setDescribeText] = useState('');
  const [generatePrompt, setGeneratePrompt] = useState('');
  const buttonRef = useRef(null);
  const menuRef = useRef(null);
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });

  const { handleAiRequest } = useAi();

  useEffect(() => {
    if (showAiMenu && buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      setMenuPosition({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX
      });
    }
  }, [showAiMenu]);

  if (!editor) return null;

  const handleAiAction = async (template, selectedText, customPrompt) => {
    if (!handleAiRequest) return;

    try {
      editor.commands.createAiNode({
        originalContent: selectedText,
        content: '',
        status: 'starting'
      });

      const response = await handleAiRequest({
        template,
        selectedText,
        customPrompt
      });

      if (!response.ok) {
        throw new Error('Failed to fetch AI response');
      }

      const reader = response.body.getReader();
      let accumulatedText = '';

      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          editor.commands.updateAiContent({
            originalContent: selectedText,
            content: accumulatedText,
            status: 'completed'
          });
          break;
        }

        const decodedChunk = new TextDecoder().decode(value, {
          stream: true
        });
        const lines = decodedChunk.split('\n');

        for (const line of lines) {
          if (line.trim() === '' || line === 'data: [DONE]') continue;

          try {
            const parsedLine = JSON.parse(line.replace('data: ', ''));
            if (parsedLine.choices[0]?.delta?.content) {
              accumulatedText += parsedLine.choices[0].delta.content;
              editor.commands.updateAiContent({
                content: accumulatedText,
                status: 'running'
              });
            }
          } catch (e) {
            console.error('Failed to parse chunk:', e);
          }
        }
      }
    } catch (error) {
      console.error('Error during AI request:', error);
      editor.commands.rejectAiContent();
    }
  };

  const hasSelection = () => {
    const { view, state } = editor;
    const { from, to } = view.state.selection;
    return from !== to;
  };

  const renderMenu = () => {
    if (!showAiMenu) return null;

    const menuContent = (
      <div
        ref={menuRef}
        className='fixed z-50'
        style={{ top: `${menuPosition.top}px`, left: `${menuPosition.left}px` }}
      >
        <div className='w-72 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5'>
          <div className='p-2'>
            <div className='flex justify-between items-center mb-2'>
              <div className='flex items-center gap-2'>
                <img
                  src='/+Ai_Logo.svg'
                  alt='AI Assistant'
                  className='w-6 h-6'
                />
                <span className='font-medium'>Assistant</span>
              </div>
              <button
                onClick={() => setShowAiMenu(false)}
                className='p-1 rounded-md hover:bg-base-200'
              >
                <X size={16} />
              </button>
            </div>

            {showDescribeInput ? (
              <>
                <div className='flex items-center gap-2 mb-2'>
                  <FlatButton
                    onClick={() => setShowDescribeInput(false)}
                    colour='primary'
                    size='sm'
                  >
                    <ArrowLeft size={16} />
                  </FlatButton>
                  <span className='font-regular text-sm'>Suggest Edits</span>
                </div>
                <textarea
                  className={`block w-full min-w-0 border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 px-2.5 py-1.5 text-sm sm:leading-6 rounded-md resize-none h-24`}
                  placeholder='Describe what changes you want to make...'
                  value={describeText}
                  onChange={e => setDescribeText(e.target.value)}
                />
                <FilledButton
                  className='w-full justify-center items-center mt-2'
                  leftIcon={<Sparkles size={16} />}
                  onClick={() => {
                    const { view, state } = editor;
                    const { from, to } = view.state.selection;
                    const text = state.doc.textBetween(from, to, '');
                    handleAiAction(21, text, describeText);
                    setDescribeText('');
                    setShowDescribeInput(false);
                    setShowAiMenu(false);
                  }}
                >
                  Generate
                </FilledButton>
              </>
            ) : hasSelection() ? (
              <>
                <div className='flex-row w-full justify-center'>
                  <SoftButton
                    className='w-full justify-center items-center'
                    leftIcon={<Sparkles size={16} />}
                    onClick={() => setShowDescribeInput(true)}
                  >
                    Suggest edits
                  </SoftButton>
                </div>
                <div className='flex-row gap-2 justify-evenly mt-2'>
                  <div className='flex gap-2'>
                    <SoftButton
                      className='w-full justify-center items-center'
                      onClick={() => {
                        const { view, state } = editor;
                        const { from, to } = view.state.selection;
                        const text = state.doc.textBetween(from, to, '');
                        handleAiAction(22, text);
                        setShowAiMenu(false);
                      }}
                      leftIcon={<TextSearch size={16} />}
                    >
                      Proof Read
                    </SoftButton>
                  </div>
                </div>
                <div className='flex-col gap-2 mt-2'>
                  <FlatButton
                    onClick={() => {
                      const { view, state } = editor;
                      const { from, to } = view.state.selection;
                      const text = state.doc.textBetween(from, to, '');
                      handleAiAction(18, text);
                      setShowAiMenu(false);
                    }}
                    className='w-full'
                    leftIcon={<Smile size={16} />}
                  >
                    Friendly
                  </FlatButton>
                  <FlatButton
                    onClick={() => {
                      const { view, state } = editor;
                      const { from, to } = view.state.selection;
                      const text = state.doc.textBetween(from, to, '');
                      handleAiAction(16, text);
                      setShowAiMenu(false);
                    }}
                    className='w-full'
                    leftIcon={<Briefcase size={16} />}
                  >
                    Professional
                  </FlatButton>
                  <FlatButton
                    onClick={() => {
                      const { view, state } = editor;
                      const { from, to } = view.state.selection;
                      const text = state.doc.textBetween(from, to, '');
                      handleAiAction(19, text);
                      setShowAiMenu(false);
                    }}
                    className='w-full'
                    leftIcon={<FoldVertical size={16} />}
                  >
                    Concise
                  </FlatButton>
                  <hr className='my-2 border-base-200' />
                  <FlatButton
                    onClick={() => {
                      const { view, state } = editor;
                      const { from, to } = view.state.selection;
                      const text = state.doc.textBetween(from, to, '');
                      handleAiAction(17, text);
                      setShowAiMenu(false);
                    }}
                    className='w-full'
                    leftIcon={<Type size={16} />}
                  >
                    Summary
                  </FlatButton>
                  <FlatButton
                    onClick={() => {
                      const { view, state } = editor;
                      const { from, to } = view.state.selection;
                      const text = state.doc.textBetween(from, to, '');
                      handleAiAction(20, text);
                      setShowAiMenu(false);
                    }}
                    className='w-full'
                    leftIcon={<List size={16} />}
                  >
                    Key Points
                  </FlatButton>
                </div>
              </>
            ) : (
              <>
                <div className='mb-3'>
                  <textarea
                    className={`block w-full min-w-0 border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 px-2.5 py-1.5 text-sm sm:leading-6 rounded-md resize-none h-24`}
                    placeholder='What would you like to generate?'
                    value={generatePrompt}
                    onChange={e => setGeneratePrompt(e.target.value)}
                  />
                </div>
                <FilledButton
                  className='w-full justify-center items-center'
                  leftIcon={<Sparkles size={16} />}
                  onClick={() => {
                    if (generatePrompt.trim()) {
                      handleAiAction(24, '', generatePrompt);
                      setGeneratePrompt('');
                      setShowAiMenu(false);
                    }
                  }}
                >
                  Generate
                </FilledButton>
              </>
            )}
          </div>
        </div>
      </div>
    );

    return createPortal(menuContent, document.body);
  };

  return (
    <div className='relative'>
      <button
        ref={buttonRef}
        onMouseDown={e => {
          e.preventDefault();
          setShowAiMenu(!showAiMenu);
        }}
        className='p-2 rounded hover:bg-base-200'
        title='AI Assistant'
      >
        <img
          src='/+Ai_Logo.svg'
          alt='AI Assistant'
          className='w-[18px] h-[18px]'
        />
      </button>

      {renderMenu()}
    </div>
  );
};

export default AiMenu;
